import React, { useRef, useState, Suspense, useEffect } from 'react';
import { window, document } from 'browser-monads';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';
import { Stats } from '@react-three/drei';
import MainCanvas from './MainCanvas';

const Renderer = ({
  canvasState,
  setCanvasState,
  setModelsLoaded,
  cameraRef,
  modelRef,
  canvasRef,
  modelUrls,
}) => {
  if (!modelUrls) {
    modelUrls = ['/model_LK5_2K_R.glb', '/model_LK4_2K.glb', '/model_LK3_2K.glb', '/model_LK2_2K.glb', '/model_LK1_2K.glb'];
  }

  const scene = useRef();

  let loaded = 0;

  const [model1, model2, model3, model4, model5] = useLoader(GLTFLoader, modelUrls, null, xhr => {
    // console.log(xhr, (xhr.loaded / xhr.total) * 100 + '% loaded');
    if (xhr.loaded / xhr.total === 1) {
      loaded++;
      setModelsLoaded(loaded);
    }
  });

  useEffect(() => {
    model1.scene.name = 'model1';
    model2.scene.name = 'model2';
    model3.scene.name = 'model3';
    model4.scene.name = 'model4';
    model5.scene.name = 'model5';

    setCanvasState({
      currentModel: model1,
      models: {
        model1: model1,
        model2: model2,
        model3: model3,
        model4: model4,
        model5: model5,
      },
    });
    ScrollTrigger.refresh();
  }, [model1, model2, model3, model4, model5]);

  return (
    <Canvas
      id="main_canvas"
      className="main-canvas"
      dpr={window.devicePixelRatio}
      style={{
        height: `100vh`,
        width: `100vw`,
        pointerEvents: `none`,
      }}
      gl={{ antialias: true, alpha: false, shadowMap: true }}
      onCreated={({ gl, scene }) => {
        canvasRef.current = gl;
        scene.current = scene;

        scene.background = new THREE.Color('#CDD0D6');

        window.scrollTo(0, 0);
        ScrollTrigger.refresh();

        setTimeout(() => {
          cameraRef.current.position.set(0, 1, 3);
          // modelRef.current.rotation.set(0, 0, 0);

          document.body.classList.remove('canvas-loading');
          document.body.classList.add('canvas-loaded');
        }, 650);
      }}
      shadowMap
      colorManagement
    >
      <MainCanvas
        canvasState={canvasState}
        setCanvasState={setCanvasState}
        cameraRef={cameraRef}
        modelRef={modelRef}
      />

      {/* <Stats /> */}
    </Canvas>
  );
};

export default Renderer;
