import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ScrollAnimation from 'react-animate-on-scroll';
import './Section5.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Video from '../../Video';

// import Video1 from '../../../static/videos/TB_5_A.mp4';
// import Video2 from '../../../static/videos/TB_5_B.mp4';
import Video3 from '../../../static/videos/TB_5_C.mp4';

const Section5 = () => {
  let textItems = [
    `articulated cropped sport coat w/ exposed shoulder pads and drawcord waist in boucle tweed with mixed bullion and satin stitch embroidered city patches`,
    `down filled articulated pleated skirt w/ dropped gaiter lining in eyelash tweed and athletic mesh with mixed bullion and satin stitch embroidered city patches`,
    `lace up corset with sweetheart cups in pique with silk faille casing`,
    `round collar shirt with thumb hole french cuffs in pique`,
    `mr.thom bag in pebble leather with hand embroidered city patches`,
    `buckled ski boots with curved heels in patent leather and brass trims`,
  ];

  return (
    <StaticQuery
      query={graphql`
        {
          imageOne: file(name: { eq: "TB_5_A" }) {
            childImageSharp {
              gatsbyImageData
            }
          }

          imageTwo: file(name: { eq: "TB_5_B" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="section-five" id="section_5">
            <div className="container heading-container is-hidden-touch is-hidden-desktop-only">
              <Heading text={`5/15`} />
            </div>
            <div className="container">
              <div className="columns is-flex-tablet main-columns">
                <div className="column is-6">
                  <Video src={Video3} aspectHeight={120} aspectWidth={89} />
                </div>
                <div className="column is-6">
                  <div className="columns is-flex">
                    <div className="column is-6 has-text">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage image={data.imageOne.childImageSharp.gatsbyImageData} alt="" />
                      </ScrollAnimation>
                      <Text
                        animated={true}
                        className="is-hidden-touch is-hidden-desktop-only"
                        items={textItems}
                      />
                    </div>
                    <div className="column is-6">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage image={data.imageTwo.childImageSharp.gatsbyImageData} alt="" />
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns is-hidden-widescreen">
                <div className="column is-12" style={{ paddingTop: `3rem` }}>
                  <Heading text={`5/15`} />
                  <Text animated={true} items={textItems} />
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

export default Section5;
