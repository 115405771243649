import React, { useEffect, useRef, forwardRef } from 'react';
import * as THREE from 'three';

const Model = forwardRef((props, ref) => {
  useEffect(() => {
    if (ref.current) {
      if (ref.current.name === 'model3' || ref.current.name === 'model5') {
        ref.current.children[0].children[1].material.transparent = true;
        ref.current.children[0].children[1].material.side = THREE.DoubleSide;
        ref.current.children[0].children[1].material.blending = THREE.MultiplyBlending;

        ref.current.children[0].children[0].material.side = THREE.DoubleSide;
      }

      if (ref.current.name === 'model2') {
        ref.current.children[0].children[1].material.transparent = true;
        ref.current.children[0].children[1].material.side = THREE.DoubleSide;
        ref.current.children[0].children[1].material.blending = THREE.MultiplyBlending;

        ref.current.children[0].children[2].material.side = THREE.DoubleSide;
      }
    }
  });

  return props.canvasState.currentModel ? (
    <mesh castShadow receiveShadow>
      <primitive
        ref={ref}
        object={props.canvasState.currentModel.scene}
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        {...props}
      />
    </mesh>
  ) : null;
});

export default Model;
