import React from 'react';
import Logo from '../../static/images/svg/logo.svg';
import './Header.scss';

const Header = () => {
  return (
    <header className="main-header">
      <div className="container">
        <div className="columns">
          <div className="column is-12 has-text-centered">
            <Logo className="logo" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
