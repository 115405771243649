import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ScrollAnimation from 'react-animate-on-scroll';
import './Section8.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Video from '../../Video';

import Video1 from '../../../static/videos/TB_8_A_vid.mp4';
import Video2 from '../../../static/videos/TB_8_B_vid.mp4';
import Video3 from '../../../static/videos/TB_8_C_vid.mp4';

const Section8 = () => {
  let textItems = [
    `draped open neck s/s sb sack overcoat with nipped waist in wool chiffon gunclub check tweed with embroidered bullion half drop stick figure athletes`,
    `corseted open neck sb sack sport coat in eyelash tweed with embroidered half drop stick figure athletes`,
    `draped pleated skirt with elasticated drawcords in cashmere houndstooth tweed and silk rep stripe mogador with embroidered bullion half drop stick figure athletes`,
    `open neck tuxedo shirt w/ elasticated drawcord bib in pique`,
    `mountaineering backpack in wool seersucker`,
    `buckle heel in pebble leather`,
  ];
  return (
    <StaticQuery
      query={graphql`
        {
          imageOne: file(name: { eq: "TB_8_A" }) {
            childImageSharp {
              gatsbyImageData
            }
          }

          imageTwo: file(name: { eq: "TB_8_B" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="section-eight" id="section_8">
            <div className="container heading-container is-hidden-touch is-hidden-desktop-only">
              <Heading text={`8/15`} />
            </div>
            <div className="container">
              <div className="columns is-flex-tablet main-columns">
                <div className="column is-6">
                  <Video src={Video1} aspectHeight={450} aspectWidth={307} />
                </div>
                <div className="column is-6">
                  <div className="columns is-flex">
                    <div className="column is-6">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage image={data.imageOne.childImageSharp.gatsbyImageData} alt="" />
                      </ScrollAnimation>
                    </div>
                    <div className="column is-6 has-text">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage image={data.imageTwo.childImageSharp.gatsbyImageData} alt="" />
                      </ScrollAnimation>
                      <Text
                        className="is-hidden-touch is-hidden-desktop-only"
                        animated={true}
                        items={textItems}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-hidden-widescreen">
                <div className="column is-12" style={{ paddingTop: `3rem` }}>
                  <Heading text={`8/15`} />
                  <Text animated={true} items={textItems} />
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

export default Section8;
