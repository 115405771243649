import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import './Section15.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Video from '../../Video';

import Video1 from '../../../static/videos/TB_15_A.mp4';

const Section15 = () => {
  let textItems = [
    `fit 1 suit and pleated skirt in mohair suiting with winter olympic city patches applique`,
    `classic pleated skirt in mohair suiting with winter olympic city patches applique`,
    `large mountaineering backpack with city patches in pebble leather`,
    `square toed hiking boots in smooth calf and brass trims`,
  ];
  return (
    <StaticQuery
      query={graphql`
        {
          imageOne: file(name: { eq: "15_A" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageTwo: file(name: { eq: "15_B" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageThree: file(name: { eq: "15_C" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageFour: file(name: { eq: "15_D" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="section-fifteen" id="section_15">
            <div className="container heading-container is-hidden-touch is-hidden-desktop-only">
              <Heading text={`15/15`} />
            </div>
            <div className="container">
              <div className="columns is-flex-tablet main-columns">
                <div className="column is-6">
                  <GatsbyImage image={data.imageFour.childImageSharp.gatsbyImageData} alt="" />
                </div>
                <div className="column is-6 float-above">
                  <div className="columns is-flex">
                    <div className="column is-4 has-text">
                      <Video src={Video1} aspectHeight={225} aspectWidth={139} />
                      <Text
                        animated={true}
                        className="is-hidden-touch is-hidden-desktop-only"
                        items={textItems}
                      />
                    </div>
                    <div className="column is-4">
                      <GatsbyImage image={data.imageTwo.childImageSharp.gatsbyImageData} alt="" />
                    </div>
                    <div className="column is-4">
                      <GatsbyImage image={data.imageThree.childImageSharp.gatsbyImageData} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-hidden-widescreen">
                <div className="column is-12" style={{ paddingTop: `3rem` }}>
                  <Heading text={`15/15`} />
                  <Text animated={true} items={textItems} />
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

export default Section15;
