import React, { useRef, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import { Suspense } from '@uploadcare/client-suspense';

import Loader from '../components/Loader';
import Renderer from '../components/Renderer';
import Footer from '../components/Footer/Footer';

import Section1 from '../components/Sections/Section1/Section1';
import Section2 from '../components/Sections/Section2/Section2';
import Section3 from '../components/Sections/Section3/Section3';
import Section4 from '../components/Sections/Section4/Section4';
import Section5 from '../components/Sections/Section5/Section5';
import Section6 from '../components/Sections/Section6/Section6';
import Section7 from '../components/Sections/Section7/Section7';
import Section8 from '../components/Sections/Section8/Section8';
import Section9 from '../components/Sections/Section9/Section9';
import Section10 from '../components/Sections/Section10/Section10';
import Section11 from '../components/Sections/Section11/Section11';
import Section12 from '../components/Sections/Section12/Section12';
import Section13 from '../components/Sections/Section13/Section13';
import Section14 from '../components/Sections/Section14/Section14';
import Section15 from '../components/Sections/Section15/Section15';

const IndexPage = () => {
  const camera = useRef();
  const model = useRef();
  const canvas = useRef();

  const [canvasState, setCanvasState] = useState({
    currentModel: null,
    models: null,
  });

  const [modelsLoaded, setModelsLoaded] = useState(0);

  return (
    <Layout>
      <SEO title="Thom Browne Press Room" />
      <Loader progress={modelsLoaded} />
      <Suspense fallback={null}>
        <Renderer
          canvasState={canvasState}
          setCanvasState={setCanvasState}
          cameraRef={camera}
          modelRef={model}
          canvasRef={canvas}
          setModelsLoaded={setModelsLoaded}
        />
      </Suspense>

      <Section1
        canvasState={canvasState}
        setCanvasState={setCanvasState}
        cameraRef={camera}
        modelRef={model}
        canvasRef={canvas}
      />
      <Section2 />
      <Section3 />
      <Section4
        canvasState={canvasState}
        setCanvasState={setCanvasState}
        cameraRef={camera}
        modelRef={model}
        canvasRef={canvas}
      />
      <Section5 />
      <Section6 />
      <Section7
        canvasState={canvasState}
        setCanvasState={setCanvasState}
        cameraRef={camera}
        modelRef={model}
        canvasRef={canvas}
      />
      <Section8 />
      <Section9 />
      <Section10
        canvasState={canvasState}
        setCanvasState={setCanvasState}
        cameraRef={camera}
        modelRef={model}
        canvasRef={canvas}
      />
      <Section11 />
      <Section12 />
      <Section13
        canvasState={canvasState}
        setCanvasState={setCanvasState}
        cameraRef={camera}
        modelRef={model}
        canvasRef={canvas}
      />
      <Section14 />
      <Section15 />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
