import React, { useRef, useEffect, useMemo } from 'react';
import gsap from 'gsap';
import './Section1.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Header from '../../Header/Header';

const Section1 = ({ setCanvasState, canvasState, cameraRef, modelRef, canvasRef }) => {
  const container = useRef();
  const section = useRef();
  const tl1 = useRef();
  const tl2 = useRef();
  const model = useRef();
  const rendered = useRef();

  useEffect(() => {
    if (canvasState.currentModel) {
      if (!tl1.current) {
        // Pin section
        tl1.current = gsap.timeline({
          scrollTrigger: {
            id: 'section pin',
            // markers: true,
            trigger: container.current,
            pin: true,
            anticipatePin: 1,
            start: 'top top',
            end: `+=${container.current.clientHeight * 2}`,
          },
        });
      }
      if (!tl2.current) {
        // Animation timeline
        tl2.current = gsap.timeline({
          scrollTrigger: {
            id: 'section animation',
            // markers: true,
            trigger: section.current,
            pin: false,
            scrub: 0.25,
            start: 'top top',
            endTrigger: `#section_2`,
            end: 'top top',
            onEnter: () => {
              setCanvasState({
                ...canvasState,
                currentModel: canvasState.models.model1,
              });
            },
            onEnterBack: () => {
              setCanvasState({
                ...canvasState,
                currentModel: canvasState.models.model1,
              });
            },
          },
        });
      }

      if (cameraRef.current) {
        tl2.current.fromTo(
          cameraRef.current.position,
          {
            y: 1,
            z: 3,
          },
          {
            y: 0.5,
            z: 1,
          },
          '0',
        );
      }
      if (modelRef.current) {
        tl2.current.fromTo(
          modelRef.current.rotation,
          {
            y: 0,
          },
          {
            y: 10,
          },
          '0',
        );
      }
    }
  }, [canvasState]);

  return (
    <div>
      <section className="section-one" ref={container} id="section_1">
        <div className="canvas-container" ref={section}>
          <Header />
          <div className="container main-container is-hidden-touch is-hidden-desktop-only">
            <div className="columns">
              <div className="column is-3">
                <Heading text={`1/15`} noAnimation={true} />
                <Text
                  items={[
                    `button back off-shoulder lapel dress with an oversized drape in gold lamé`,
                    `oversized tailcoat gown with drawstrings and backpack straps in gold lamé`,
                    `mr. thom backpack in mirror finish gold leather<br/>buckled ski boots with curved heels in mirror finish gold leather`,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section-one-mobile-text mobile-text is-hidden-widescreen">
        <div className="container ">
          <div className="columns">
            <div className="column is-3">
              <Heading text={`1/15`} noAnimation={true} />
              <Text
                items={[
                  `button back off-shoulder lapel dress with an oversized drape in gold lamé`,
                  `oversized tailcoat gown with drawstrings and backpack straps in gold lamé`,
                  `mr. thom backpack in mirror finish gold leather<br/>buckled ski boots with curved heels in mirror finish gold leather`,
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
