import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ScrollAnimation from 'react-animate-on-scroll';
import './Section6.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Video from '../../Video';

// import Video1 from '../../../static/videos/TB_6_A.mp4';
import Video2 from '../../../static/videos/TB_6_B.mp4';
// import Video3 from '../../../static/videos/TB_6_C.mp4';

const Section6 = () => {
  let textItems = [
    `oversized rugby stripe downfilled cape with backpack straps in satin finish tech and twill tech`,
    `oversized chesterfield in military weight cashmere with duchess satin lapel and mr. & mrs. thom snowflakes applique`,
    `oversized jacket and skirt in cavalry twill with satin lapels and drawstring details`,
    `ski puffer mr.thom backpack in nylon`,
    `buckled ski boots with curved heels in pebble leather and brass trims`,
  ];
  return (
    <StaticQuery
      query={graphql`
        {
          imageOne: file(name: { eq: "6_A" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageTwo: file(name: { eq: "TB_6_A" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageThree: file(name: { eq: "TB_6_C" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="section-six" id="section_6">
            <div className="container heading-container is-hidden-touch is-hidden-desktop-only">
              <Heading text={`6/15`} />
            </div>
            <div className="container">
              <div className="columns is-flex-tablet main-columns">
                <div className="column is-6 has-text">
                  <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <GatsbyImage image={data.imageOne.childImageSharp.gatsbyImageData} alt="" />
                  </ScrollAnimation>
                </div>

                <div className="column is-6 float-above">
                  <div className="columns is-flex">
                    <div className="column is-4 ">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage image={data.imageTwo.childImageSharp.gatsbyImageData} alt="" />
                      </ScrollAnimation>
                    </div>
                    <div className="column is-4">
                      <Video src={Video2} aspectHeight={207} aspectWidth={139} />{' '}
                    </div>
                    <div className="column is-4">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage
                          image={data.imageThree.childImageSharp.gatsbyImageData}
                          alt=""
                        />
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="columns" style={{ paddingTop: `3rem` }}>
                <div className="column is-4">
                  <Heading text={`6/15`} className="is-hidden-widescreen" />
                  <Text animated={true} items={textItems} />
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

export default Section6;
