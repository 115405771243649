import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import './Section14.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Video from '../../Video';

import Video1 from '../../../static/videos/TB_14_A.mp4';

const Section14 = () => {
  let textItems = [
    `reconstructed draped chesterfield o/c in mackintosh w/ trompe l’oeil bullion mitten, draped smocking classic shirt in pique, pleated skirt in smocked military ripstop, and girdle with lace up back in heavy athletic mesh`,
    `carry on suitcase w. city patches in box calf`,
    `buckled ski boots with curved heels in patent leather and brass trims`,
  ];
  return (
    <StaticQuery
      query={graphql`
        {
          imageOne: file(name: { eq: "14_A" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageTwo: file(name: { eq: "14_B" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageThree: file(name: { eq: "14_C" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="section-fourteen" id="section_14">
            <div className="container heading-container is-hidden-touch is-hidden-desktop-only">
              <Heading text={`14/15`} />
            </div>
            <div className="container">
              <div className="columns is-flex-tablet main-columns">
                <div className="column is-6">
                  <GatsbyImage image={data.imageOne.childImageSharp.gatsbyImageData} alt="" />
                </div>
                <div className="column is-6">
                  <div className="columns is-flex">
                    <div className="column is-6">
                      <GatsbyImage image={data.imageTwo.childImageSharp.gatsbyImageData} alt="" />
                    </div>
                    <div className="column is-6 has-text">
                      <Video src={Video1} aspectHeight={450} aspectWidth={307} />

                      <Text
                        animated={true}
                        className="is-hidden-touch is-hidden-desktop-only"
                        items={textItems}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-hidden-widescreen">
                <div className="column is-12" style={{ paddingTop: `3rem` }}>
                  <Heading text={`14/15`} />
                  <Text animated={true} items={textItems} />
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

export default Section14;
