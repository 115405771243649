import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ScrollAnimation from 'react-animate-on-scroll';
import './Section9.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Video from '../../Video';

// import Video1 from '../../../static/videos/TB_9_A.mp4';
// import Video2 from '../../../static/videos/TB_9_B.mp4';
import Video3 from '../../../static/videos/TB_9_C.mp4';

const Section9 = () => {
  let textItems = [
    `oversized double breasted chesterfield in silk faille with stiff tulle panels and drawstring details with downfilled embroidered bow`,
    `downfilled jacket in military ripstop with drawstring details`,
    `downfilled skirt in military ripstop with drawstring details and tulle underpinning`,
    `hector bag with ski jacket in pebble leather`,
    `buckled ski boots with curved heels in pebble leather and brass trims`,
  ];
  return (
    <StaticQuery
      query={graphql`
        {
          imageOne: file(name: { eq: "9_D" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageTwo: file(name: { eq: "9_A" }) {
            childImageSharp {
              gatsbyImageData
            }
          }

          imageThree: file(name: { eq: "9_B" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="section-nine" id="section_9">
            <div className="container heading-container is-hidden-touch is-hidden-desktop-only">
              <Heading text={`9/15`} />
            </div>
            <div className="container">
              <div className="columns is-flex-tablet main-columns">
                <div className="column is-6">
                  <GatsbyImage image={data.imageOne.childImageSharp.gatsbyImageData} alt="" />
                </div>
                <div className="column is-6 float-above">
                  <div className="columns is-flex">
                    <div className="column is-4 has-text">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage image={data.imageTwo.childImageSharp.gatsbyImageData} alt="" />
                      </ScrollAnimation>
                      <Text className="is-hidden-tablet" items={textItems} animated={true} />
                    </div>
                    <div className="column is-4">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage
                          image={data.imageThree.childImageSharp.gatsbyImageData}
                          alt=""
                        />
                      </ScrollAnimation>
                    </div>
                    <div className="column is-4">
                      <Video src={Video3} aspectHeight={227} aspectWidth={139} />{' '}
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns is-hidden-desktop">
                <div className="column is-12" style={{ paddingTop: `3rem` }}>
                  <Heading text={`9/15`} />
                  <Text animated={true} items={textItems} />
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

export default Section9;
