import React, { useEffect, useRef } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const Text = ({ text, noAnimation, className }) => {
  if (text) {
    if (noAnimation === true) {
      return <h2 className={className}>{text}</h2>;
    } else {
      return (
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <h2 className={className}>{text}</h2>
        </ScrollAnimation>
      );
    }
  } else {
    return null;
  }
};

export default Text;
