import React from 'react';
import LazyLoad from 'react-lazyload';
import ScrollAnimation from 'react-animate-on-scroll';

const Video = ({ src, aspectHeight, aspectWidth }) => {
  const paddingBottom = (aspectHeight / aspectWidth) * 100;

  return (
    <div
      className="html5-video-container"
      style={{
        width: `100%`,
        position: `relative`,
        overflow: `hidden`,
        height: 0,
        paddingBottom: `${paddingBottom}%`,
        backgroundColor: `#CDD0D6`,
      }}
    >
      <LazyLoad offset={200}>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <video
            src={src}
            autoPlay
            playsInline
            muted
            loop
            style={{
              width: `calc(100% + 1rem)`,
              position: `absolute`,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          />
        </ScrollAnimation>
      </LazyLoad>
    </div>
  );
};

export default Video;
