import React, { useEffect, useRef } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const Text = ({ items, className, animated }, props) => {
  return (
    <ul className={`text-items ${className}`} style={{ listStyle: `none` }} {...props}>
      {items?.map((item, index) => {
        return (
          <li key={`textItem_i_${index}`} style={{ marginBottom: `1.25rem` }}>
            {animated ? (
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <span dangerouslySetInnerHTML={{ __html: item }} />
              </ScrollAnimation>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: item }} />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Text;
