import React, { useRef, useEffect, useMemo } from 'react';
import gsap, { Linear } from 'gsap';
import './Section7.scss';

import Heading from '../../Heading';
import Text from '../../Text';

const Section7 = ({ setCanvasState, canvasState, cameraRef, modelRef, canvasRef }) => {
  const container = useRef();
  const section = useRef();
  const pinTimeline = useRef();
  const loadModelTrigger = useRef();
  const cameraScrollTimeline = useRef();
  const cameraTimeline = useRef();
  const animationTimeline = useRef();
  const model = useRef();
  const rendered = useRef();

  useEffect(() => {
    if (canvasState.currentModel) {
      if (!pinTimeline.current) {
        pinTimeline.current = gsap.timeline({
          scrollTrigger: {
            id: 'section pin',
            // markers: true,
            trigger: container.current,
            pin: true,
            anticipatePin: 1,
            start: 'top top',
            end: `+=${container.current.clientHeight * 1.75}`,
          },
        });
      }
      if (!loadModelTrigger.current) {
        loadModelTrigger.current = gsap.timeline({
          scrollTrigger: {
            id: 'section animation',
            // markers: true,
            trigger: section.current,
            pin: false,
            scrub: false,
            start: 'top-=750 100%',
            endTrigger: '#section_8',
            end: `top top`,
            onEnter: () => {
              if (cameraRef.current) {
                cameraRef.current.position.set(0, 3.5, 2.75);
              }
              setCanvasState({
                ...canvasState,
                currentModel: canvasState.models.model4,
              });
            },
          },
        });
      }

      if (!cameraScrollTimeline.current) {
        cameraScrollTimeline.current = gsap.timeline({
          scrollTrigger: {
            id: 'camera-scroll',
            // markers: true,
            trigger: section.current,
            pin: false,
            scrub: 0,
            start: 'top 100%',
            end: `+=${container.current.clientHeight}`,
          },
        });
      }

      if (!cameraTimeline.current) {
        cameraTimeline.current = gsap.timeline({
          scrollTrigger: {
            id: 'section animation',
            // markers: true,
            trigger: section.current,
            pin: false,
            scrub: 0.25,
            start: 'top 100%',
            endTrigger: '#section_8',
            end: `top top`,
            onEnterBack: () => {
              setCanvasState({
                ...canvasState,
                currentModel: canvasState.models.model4,
              });
            },
          },
        });
      }

      if (!animationTimeline.current) {
        animationTimeline.current = gsap.timeline({
          scrollTrigger: {
            id: 'section animation',
            // markers: true,
            trigger: section.current,
            pin: false,
            scrub: 0.25,
            start: 'top top',
            endTrigger: '#section_8',
            end: `top top`,
          },
        });
      }
    }
  }, [canvasState]);

  useEffect(() => {
    if (cameraRef.current) {
      cameraScrollTimeline.current.fromTo(
        cameraRef.current.position,
        {
          y: 3.5,
          z: 2.75,
          onStart: () => {
            if (modelRef.current) {
              modelRef.current.rotation.y = 0;
            }
          },
        },
        {
          y: 1.25,
          z: 2.75,
          ease: Linear.easeNone,
        },
        '0',
      );
    }
    if (modelRef.current) {
      animationTimeline.current.fromTo(
        modelRef.current.rotation,
        {
          y: 0,
        },
        {
          y: 10,
        },
        '0',
      );
      animationTimeline.current.fromTo(
        cameraRef.current.position,
        {
          y: 1.25,
          z: 2.75,
        },
        {
          y: 0.75,
          z: 1,
        },
        '0',
      );
    }
  }, [canvasState]);

  return (
    <div>
      <section className="section-seven" ref={container} id="section_7">
        <div className="canvas-container" ref={section}>
          <div className="container main-container is-hidden-touch is-hidden-desktop-only">
            <div className="columns">
              <div className="column is-3">
                <Heading text={`7/15`} />
                <Text
                  items={[
                    `button back trompe l’oeil jacket top with smocked and pleated drape in 4 bar sateen stripe school uniform suiting and scattered pearl embroidery`,
                    `button front skirt with smocked and pleated drape in 4 bar sateen stripe school uniform suiting and scattered pearl embroidery`,
                    `corset in pique with satin binding`,
                    `hector bag on skates in pebble leather`,
                    `buckled ski boots with curved heels in pebble leather and brass trims`,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section-seven-mobile-text mobile-text is-hidden-widescreen">
        <div className="container ">
          <div className="columns">
            <div className="column is-3">
              <Heading text={`7/15`} noAnimation={true} />
              <Text
                items={[
                  `button back trompe l’oeil jacket top with smocked and pleated drape in 4 bar sateen stripe school uniform suiting and scattered pearl embroidery`,
                  `button front skirt with smocked and pleated drape in 4 bar sateen stripe school uniform suiting and scattered pearl embroidery`,
                  `corset in pique with satin binding`,
                  `hector bag on skates in pebble leather`,
                  `buckled ski boots with curved heels in pebble leather and brass trims`,
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section7;
