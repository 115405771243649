import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import './Section11.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Video from '../../Video';

import Video1 from '../../../static/videos/TB_11_A.mp4';

const Section11 = () => {
  let textItems = [
    `fit 2 overcoat in ski mountain intarsia in assorted fabrics`,
    `fit 2 suit and skirt in ski mountain intarsia in assorted fabrics`,
    `hector trolley in pebble grain`,
    `buckled ski boots in patent leather and brass trims`,
  ];
  return (
    <StaticQuery
      query={graphql`
        {
          imageOne: file(name: { eq: "11_A" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageTwo: file(name: { eq: "11_B" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageThree: file(name: { eq: "11_C" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="section-eleven" id="section_11">
            <div className="container heading-container is-hidden-touch is-hidden-desktop-only">
              <Heading text={`11/15`} />
            </div>
            <div className="container">
              <div className="columns is-flex-tablet main-columns">
                <div className="column is-6">
                  <GatsbyImage image={data.imageThree.childImageSharp.gatsbyImageData} alt="" />
                </div>
                <div className="column is-6">
                  <div className="columns is-flex">
                    <div className="column is-6 has-text">
                      <Video src={Video1} aspectHeight={453} aspectWidth={307} />
                      <Text
                        animated={true}
                        className="is-hidden-touch is-hidden-desktop-only"
                        items={textItems}
                      />
                    </div>
                    <div className="column is-6">
                      <GatsbyImage image={data.imageTwo.childImageSharp.gatsbyImageData} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-hidden-widescreen">
                <div className="column is-12" style={{ paddingTop: `3rem` }}>
                  <Heading text={`11/15`} />
                  <Text animated={true} items={textItems} />
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

export default Section11;
