import React, { useRef, useLayoutEffect, useEffect } from 'react';
import { useThree } from '@react-three/fiber';

const Camera = React.forwardRef((props, ref) => {
  const set = useThree(({ set }) => set);
  const size = useThree(({ size }) => size);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.aspect = size.width / size.height;
      ref.current.updateProjectionMatrix();
    }
  }, [size, props, ref]);

  useLayoutEffect(() => {
    set({ camera: ref.current });
  });

  return <perspectiveCamera ref={ref} {...props} />;
});

export default Camera;
