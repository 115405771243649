/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import 'antd/dist/antd.css';
import '../styles/style.scss';

const Layout = ({ children }) => {
  return <div>{children}</div>;
};

export default Layout;
