import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ScrollAnimation from 'react-animate-on-scroll';
import './Section2.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Video from '../../Video';

import Video1 from '../../../static/videos/TB_2_A.mp4';
import Video2 from '../../../static/videos/TB_2_B.mp4';

const Section2 = () => {
  let textItems = [
    `morning coat shrug in black cashmere seersucker with oversized silk faille bow, exposed shoulder pads, and drawcord details`,
    `deconstructed gown in trompe l’oeil bullion knit bodice, down filled nylon ripstop skirt w/ winter toile scenic quilting, and mr. & mrs. thom snowflake fair isle lace`,
    `articulated down fill tuxedo shirtdress in mr. and mrs. thom snowflake embroidered ripstop and pique buckled ski boots with curved heels in patent leather and brass trims`,
  ];
  return (
    <StaticQuery
      query={graphql`
        {
          image: file(name: { eq: "TB_2" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="section-two" id="section_2">
            <div className="container heading-container is-hidden-touch is-hidden-desktop-only">
              <Heading text={`2/15`} />
            </div>
            <div className="container">
              <div className="columns is-flex-tablet main-columns">
                <div className="column is-6">
                  <Video src={Video1} aspectHeight={120} aspectWidth={89} />
                </div>
                <div className="column is-6">
                  <div className="columns is-flex">
                    <div className="column is-6">
                      <Video src={Video2} aspectHeight={456} aspectWidth={307} />
                    </div>
                    <div className="column is-6 has-text">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage image={data.image.childImageSharp.gatsbyImageData} alt="" />
                      </ScrollAnimation>

                      <Text
                        className="is-hidden-touch is-hidden-desktop-only"
                        animated={true}
                        items={textItems}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns is-hidden-widescreen">
                <div className="column is-12" style={{ paddingTop: `3rem` }}>
                  <Heading text={`2/15`} />
                  <Text animated={true} items={textItems} />
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

export default Section2;
