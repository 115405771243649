import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import Header from './Header/Header';

import HeroVideo from '../static/videos/TB_Hero_Video.mp4';

const Loader = ({ progress }) => {
  const [trickle, setTrickle] = useState(0);
  let trickleTimer;
  let trickleValue = 0;

  useEffect(() => {
    if (progress === 0 || !progress) {
      trickleTimer = setInterval(() => {
        trickleValue++;
        setTrickle(trickleValue);
      }, 3000);
    } else {
      clearInterval(trickleTimer);
    }
  }, [progress]);

  return (
    <div className="page-loader">
      <Header />
      <Progress
        percent={progress ? (progress / 5) * 100 : trickle}
        showInfo={false}
        strokeLinecap="square"
      />

      <div className="video-container">
        <div className="overlay" />
        <video src={HeroVideo} autoPlay playsInline muted loop />
      </div>
    </div>
  );
};

export default Loader;
