import React, { Suspense, useRef, useEffect } from 'react';
import { Stage, Environment } from '@react-three/drei';
import Camera from './Camera';
import Model from './Model';

const MainCanvas = ({ canvasState, setCanvasState, cameraRef, modelRef }) => {
  const lightRef = useRef();

  return (
    <group receiveShadow>
      <Camera position={[0, 1, 3]} ref={cameraRef} />
      <Suspense fallback={null}>
        <directionalLight
          castShadow
          ref={lightRef}
          position={[0, 4, 3]}
          rotation={[0, 0, 0]}
          intensity={0.35}
          shadow-mapSize-height={128}
          shadow-mapSize-width={128}
        />

        {/* {lightRef.current ? <directionalLightHelper args={[lightRef.current, 1]} /> : null} */}

        <Stage
          contactShadow // Optional: creates a contactshadow underneath the content (default=true)
          shadows // Optional: lights cast shadow (default=true)
          intensity={0.1} // Optional: light intensity (default=1)
          environment="city" // Optional: environment (default=city)
          preset="rembrandt" // Optional: rembrandt (default) | portrait | upfront | soft
        >
          <Model ref={modelRef} setCanvasState={setCanvasState} canvasState={canvasState} />
        </Stage>

        <Environment background={false} preset={`city`} scene={undefined} />
      </Suspense>
    </group>
  );
};

export default MainCanvas;
