import React from 'react';
import { window } from 'browser-monads';
import Text from '../Text';
import './Footer.scss';

const Footer = () => {
  return (
    <footer>
      <div className="press-info">
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <Text
                animated={true}
                items={[
                  `PRESS CONTACT:`,
                  `THOM BROWNE<br/>
                Public Relations<br/>
                Matthew Foley / Vice President of Communications<br/>
                matthew@thombrowne.com`,
                  `Jonathon Zadrzynski / Press Manager<br/>
                jonathon@thombrowne.com`,
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="callout-link">
        <div className="container">
          <span>{`... all in a day’s work ...`}</span>
        </div>
      </div>
      <div className="main-footer">
        <div className="container">
          <ul>
            <li>
              <span
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >{`Back to Top`}</span>
            </li>
            <li>
              <span>
                <a href="https://thombrowne.com" target="_blank">
                  {`thombrowne.com`}
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
