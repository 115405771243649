import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import './Section12.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Video from '../../Video';

import Video1 from '../../../static/videos/TB_12_A.mp4';
import Video2 from '../../../static/videos/TB_12_B.mp4';
import Video3 from '../../../static/videos/TB_12_C.mp4';

const Section12 = () => {
  let textItems = [
    `sleeveless articulated cropped sport coat w/ beaded exposed shoulder pads and drawcord waist in small gunclub overcheck hunting tweed`,
    `down filled articulated cuffed skirt w/ dropped gaiter lining in wide rep stripe wool cotton suiting and nylon ripstop`,
    `lace up corset with sweetheart cups in fair isle embroidered ripstop with silk faille casing`,
    `short sleeve gathered volume shirt with tuxedo bib in pique`,
    `down filled articulated opera gloves in small gunclub overcheck hunting tweed, silk faille, and nylon ripstop`,
    `hector limousine handbag in black pebble leather`,
    `buckled ski boots with curved heels in pebble leather and brass trims`,
  ];
  return (
    <StaticQuery
      query={graphql`
        {
          imageOne: file(name: { eq: "12_A" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageTwo: file(name: { eq: "12_B" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageThree: file(name: { eq: "12_C" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageFour: file(name: { eq: "12_D" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="section-twelve" id="section_12">
            <div className="container heading-container is-hidden-touch is-hidden-desktop-only">
              <Heading text={`12/15`} />
            </div>
            <div className="container">
              <div className="columns is-flex-tablet main-columns">
                <div className="column is-6 has-text">
                  <GatsbyImage image={data.imageOne.childImageSharp.gatsbyImageData} alt="" />
                </div>
                <div className="column is-6 float-above">
                  <div className="columns is-flex">
                    <div className="column is-4 ">
                      <GatsbyImage image={data.imageTwo.childImageSharp.gatsbyImageData} alt="" />
                    </div>
                    <div className="column is-4">
                      <GatsbyImage image={data.imageThree.childImageSharp.gatsbyImageData} alt="" />
                    </div>
                    <div className="column is-4">
                      <GatsbyImage image={data.imageFour.childImageSharp.gatsbyImageData} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="columns">
                <div className="column is-4">
                  <Heading text={`12/15`} className="is-hidden-widescreen" />
                  <Text animated={true} items={textItems} />
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

export default Section12;
