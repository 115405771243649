import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ScrollAnimation from 'react-animate-on-scroll';
import './Section3.scss';

import Heading from '../../Heading';
import Text from '../../Text';
import Video from '../../Video';

// import Video1 from '../../../static/videos/TB_3_A.mp4';
// import Video2 from '../../../static/videos/TB_3_B.mp4';
import Video3 from '../../../static/videos/TB_3_C.mp4';

const Section3 = () => {
  let textItems = [
    `one sided draped jacket with smocked and pleated satin faced organza`,
    `button back skirt with smocked and pleated drape in satin faced organza corset in pique with satin binding`,
    `cable knit vest in cashmere`,
    `button down round collar long sleeve tux shirt in pique with stacked shoulder pads`,
    `hector bag in tuxedo in pebble leather`,
    `buckled ski boots with curved heels in patent leather and brass trims`,
  ];

  return (
    <StaticQuery
      query={graphql`
        {
          imageOne: file(name: { eq: "3_D" }) {
            childImageSharp {
              gatsbyImageData
            }
          }

          imageTwo: file(name: { eq: "TB_3_A" }) {
            childImageSharp {
              gatsbyImageData
            }
          }

          imageThree: file(name: { eq: "TB_3_B" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="section-three" id="section_3">
            <div className="container heading-container is-hidden-touch is-hidden-desktop-only">
              <Heading text={`3/15`} />
            </div>
            <div className="container">
              <div className="columns is-flex-tablet main-columns">
                <div className="column is-6">
                  <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <GatsbyImage image={data.imageOne.childImageSharp.gatsbyImageData} alt="" />
                  </ScrollAnimation>
                </div>

                <div className="column is-6 float-above">
                  <div className="columns is-flex">
                    <div className="column is-4 has-text">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage image={data.imageTwo.childImageSharp.gatsbyImageData} alt="" />
                      </ScrollAnimation>
                      <Text
                        className="is-hidden-touch is-hidden-desktop-only"
                        animated={true}
                        items={textItems}
                      />
                    </div>
                    <div className="column is-4">
                      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <GatsbyImage
                          image={data.imageThree.childImageSharp.gatsbyImageData}
                          alt=""
                        />
                      </ScrollAnimation>
                    </div>
                    <div className="column is-4">
                      <Video src={Video3} aspectHeight={456} aspectWidth={307} />{' '}
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns is-hidden-widescreen">
                <div className="column is-12" style={{ paddingTop: `3rem` }}>
                  <Heading text={`3/15`} />
                  <Text animated={true} items={textItems} />
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

export default Section3;
